<script >
import {
  onMounted,
  defineComponent,
  ref,
  onUnmounted,
  computed,
  reactive,
} from "vue"
import Button from "../components/Button.vue"
import Icon from "../components/Icon.vue"
// import {
//   IFCWINDOW,
//   IFCSPACE,
//   IFCWALL,
//   IFCBUILDINGELEMENT,
//   IFCFURNISHINGELEMENT,
//   IFCROOF,
//   IFCSLAB,
//   IFCDOOR,
//   IFCRAILING,
//   IFCCURTAINWALL,
// } from "web-ifc"
// import * as WEBIFC from "web-ifc/web-ifc-api"
import { useRoute, useRouter } from "vue-router"
import { useStore } from "vuex"
import { usePerms } from "@/logic/usePerms"
import axios from "axios"

export default defineComponent({
  components: {
    Button,
    Icon,
  },
  props: {
    id: String,
    pid: String,
    jobStatus: Number,
    included: Boolean,
  },
  setup(props) {
    let dragoverevent, dropevent

    let selectedPackage = ref()
    let paymentMethod = ref("stripe")

    const router = useRouter()
    const store = useStore()
    const { hasPerm, checkToken } = usePerms()

    const token = computed(() => store.getters["auth/token"])

    onMounted(() => {
      dragoverevent = window.addEventListener(
        "dragover",
        function (e) {
          e = e || event
          e.preventDefault()
        },
        false
      )
      dropevent = window.addEventListener(
        "drop",
        function (e) {
          e = e || event
          e.preventDefault()
        },
        false
      )

      getFiles()
      getProducts()
    })

    onUnmounted(() => {
      window.removeEventListener("dragover", dragoverevent)
      window.removeEventListener("drop", dropevent)
    })
    // const url = "http://localhost:8085"
    const url = "https://api.otonomi.no"

    const fileinput = ref(null)
    const files = ref([])

    const totalcomplex = computed(() => {
      let complex = 0
      for (let i = 0; i < files.value.length; i++) {
        const element = files.value[i]
        if (element.ext == "ifc" && element.complex != undefined) {
          complex += element.complex
        }
      }
      return complex
    })

    const productSize = computed(() => {
      let complex = 0
      for (const [key, value] of Object.entries(products.value)) {
        const productComplexity = value.COMPLEX
        if (
          productComplexity[0] < totalcomplex.value &&
          totalcomplex.value <= productComplexity[1]
        ) {
          selectedPackage.value = Object.entries(value.PACKAGE)[1][0]
          return key
        }
      }
    })

    const canProcessOrder = computed(() => {
      let points = 0
      for (const [key, value] of Object.entries(ifcObjs.value)) {
        if (value.points == 0) {
          points += 1
        }
      }
      return points == 0
    })

    const ifcObjs = computed(() => {
      const objs = {
        IFCSPACE: { size: 0, points: 0, icon: "border_style" },
        IFCWINDOW: { size: 0, points: 0, icon: "border_all" },
        // IFCWALL: { size: 0, points: 0, icon: "crop_16_9" },
        // IFCWALLSTANDARDCASE: { size: 0, points: 0, icon: "crop_16_9" },
        // IFCDOOR: { size: 0, points: 0, icon: "meeting_room" },
      }
      for (let i = 0; i < files.value.length; i++) {
        const element = files.value[i]
        if (element.ext == "ifc" && element.content != undefined) {
          for (const [key, value] of Object.entries(element.content)) {
            // add to standard keys
            if (objs[key] != undefined) {
              objs[key].size += element.content[key]["size"]
              objs[key].points += element.content[key]["points"]
            }
            // unrequired keys
            else {
              objs[key] = {
                size: element.content[key]["size"],
                points: element.content[key]["points"],
                icon: "trip_origin",
              }
            }
          }
        }
      }
      return objs
    })
    const invoceform = reactive({})
    const comment = ref("")

    const processOrder = async () => {
      await checkToken()
      let data = {
        method: paymentMethod.value,
        defer: invoceform.defer || false,
        jobid: props.id,
        product: selectedPackage.value,
        count: 1,
      }
      if (invoceform.defer) data.invoicedata = invoceform

      if (props.included) {
        data = {
          method: "included",
          jobid: props.id,
          product: "SINGLE",
          count: 1,
        }
      }

      axios({
        baseURL: url, //"http://localhost:8085",
        url: "/order",
        data,
        method: "POST",
        headers: {
          Authorization: "Bearer " + token.value,
        },
      })
        .then((resp) => {
          if (resp.data.url) {
            //open url
            window.location.href = resp.data.url
          } else {
            router.push(`/projects/${props.pid}/${props.id}`)
          }
          console.log(resp)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    const products = ref({})
    const getProducts = async () => {
      if (props.id) {
        await checkToken()

        axios({
          baseURL: url,
          url: "/order/products/",
          data: {},
          method: "GET",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            products.value = resp.data
          })
          .catch((err) => {
            console.log(err)
          })
      }

      // job/files/jid'/files/:jid'
      // project/files/pid
      // if access returns a list of files with all data.
    }

    const getFiles = async () => {
      if (props.id) {
        await checkToken()

        axios({
          baseURL: url,
          url: "/job/files/" + props.id,
          data: {},
          method: "GET",
          headers: {
            Authorization: "Bearer " + token.value,
          },
        })
          .then((resp) => {
            console.log(resp)

            files.value = resp.data.files

            // projects.value = p
          })
          .catch((err) => {
            console.log(err)
          })
      }

      // job/files/jid'/files/:jid'
      // project/files/pid
      // if access returns a list of files with all data.
    }

    const deletefile = async (id) => {
      await checkToken()

      axios({
        baseURL: url,
        url: "/job/files/" + id,
        data: {},
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token.value,
        },
      })
        .then((resp) => {
          console.log(resp)

          const index = files.value.findIndex((v) => {
            return v._id == id
          })
          if (index >= 0) {
            files.value.splice(index, 1)
          }

          // projects.value = p
        })
        .catch((err) => {
          console.log(err)
        })
    }

    const upload = async (file) => {
      const index = files.value.findIndex((v) => {
        return v.name == file.name
      })

      const fileobject = {
        name: file.name,
        ext: file.name.split(".")[file.name.split(".").length - 1],
        progress: 55,
        size: file.size,
        id: "",
      }
      if (index < 0) {
        files.value.push(fileobject)
      } else {
        files.value[index] = fileobject
      }

      let formData = new FormData()
      formData.append("file", file)
      // add file to filelist
      await checkToken()

      axios({
        baseURL: url,
        url: "/job/upload/" + props.id,
        data: formData,
        method: "POST",

        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + token.value,
        },
        onUploadProgress: function (progressEvent) {
          const index = files.value.findIndex((v) => {
            return v.name == file.name
          })
          files.value[index].progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          // progress.value = percentCompleted
          // console.log(percentCompleted)
        },
      })
        .then((resp) => {
          console.log(resp)
          const index = files.value.findIndex((v) => {
            return v.name == resp.data.file.name
          })
          console.log(index)
          if (index >= 0) {
            files.value[index] = resp.data.file
          }
          // if (resp.data.project == project.value._id) {
          // project.value.jobs.push(resp.data)
          // }
        })
        .catch((err) => {
          console.log(err)
        })
      // upload file.
      // add event handler to update file in filelist with progress.
      // on return replace file in filelist with file object from request
    }

    const drophandler = async (event) => {
      // const file = event.dataTransfer.files[0] //event.target.files[0]
      console.log(event.dataTransfer.files)

      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        // filehandler(event.dataTransfer.files[i])
        upload(event.dataTransfer.files[i])
      }
    }
    const uploadhandler = async (event) => {
      const file = event.target.files[0]
      // filehandler(file)
      upload(file)
    }
    const filehandler = async (file) => {
      console.log("drop")
      // fileUploaded = !fileUploaded
      let ifcURL = URL.createObjectURL(file)
      const text = await fetch(ifcURL).then((res) => res.text())
      let time = Date.now()
      const lines = text.split(");\r\n")
      console.log(Number(lines[lines.length - 2].match(/[0-9]\w+/)[0]))
      const ifcArray = Array(
        Number(lines[lines.length - 2].match(/[0-9]\w+/)[0])
      )

      for (let i = 0; i < lines.length; i++) {
        if (lines[i].substring(0, 1) == "#") {
          ifcArray[Number(lines[i].match(/[0-9]\w+/))] = lines[i]
        }
      }
      // console.log(ifcArray)
      // const getlinebyid = (id) => {
      //   // console.log(id, Number(id.substring(1, id.length)))

      //   let line = ifcArray[Number(id.substring(1, id.length))]

      //   const key = line.match(/[A-Z]\w+/)
      //   line = line.split(/[A-Z]\w+\(/)
      //   line = ("[" + line[1] + "]").replace("(", "[").replace(")", "]") //.split(",")
      //   const obj = {}
      //   obj[key] = JSON.parse(line)
      //   return obj
      // }

      // const spaces = lines
      //   .filter((v) => {
      //     return v.includes("IFCSPACE(")
      //   })
      //   .map((v) => {
      //     let space = v.split("IFCSPACE(")[1]
      //     space = space.split(",")
      //     space[5] = getlinebyid(space[5])
      //     space[6] = getlinebyid(space[6])
      //     return space
      //   })
      // console.log(spaces)
      // console.log("time", Date.now() - time)
      // // let modelID = ifcApi.OpenModel(text)
      // const pointsystem = {
      //   IFCCURTAINWALL: {
      //     name: "IFCCURTAINWALL",
      //     point: 5,
      //     id: IFCCURTAINWALL,
      //   },
      //   IFCSPACE: { name: "IFCSPACE", point: 3, id: IFCSPACE },
      //   IFCWINDOW: { name: "IFCWINDOW", point: 1, id: IFCWINDOW },
      //   IFCWALL: { name: "IFCWALL", point: 1, id: IFCWALL },
      //   IFCWALLSTANDARDCASE: {
      //     name: "IFCWALLSTANDARDCASE",
      //     point: 1,
      //     id: IFCWALL,
      //   },
      //   IFCDOOR: { name: "IFCDOOR", point: 0.8, id: IFCDOOR },
      // }
      // let totalPoints = 0
      // for (let key in pointsystem) {
      //   const element = pointsystem[key]
      //   const size = text.split(`${element.name}(`).length

      //   // const size = ifcApi.GetLineIDsWithType(modelID, element.id).size()
      //   const points = size * element.point
      //   console.log(element.name, size, points)
      //   totalPoints += points
      // }
      // console.log("total points:", totalPoints)
    }

    const updatejob = async (property, value, jid) => {
      if (!store.getters["auth/isLogedIn"]) {
        store.commit("setModal", 2)
        return
      }
      await checkToken()
      console.log(property, value, jid)
      axios({
        baseURL: url,
        url: "/job/" + jid,
        data: {
          [property]: value,
        },
        method: "PATCH",
        headers: {
          Authorization: "Bearer " + token.value,
        },
      })
        .then((resp) => {
          console.log(resp)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    return {
      company: computed(() => store.getters["auth/company"]),
      drophandler,
      uploadhandler,
      fileinput,
      files,
      ifcObjs,
      totalcomplex,
      deletefile,
      selectedPackage,
      paymentMethod,
      products,
      productSize,
      canProcessOrder,
      processOrder,
      invoceform,
      comment,
      updatejob,
    }
  },
})
</script>

<template>
  <main>
    <section class="page">
      <section class="new-job">
        <div class="content">
          <!-- BEGIN ORDER -->
          <div class="order-header text-center">
            <div class="project-size-info-title">Your Order</div>
          </div>

          <div class="section-title">
            <h4 v-if="files.length == 0">Upload your IFC files</h4>
            <h4 v-else>Uploaded IFC files</h4>
          </div>

          <div class="user-details">
            <p class="light-text" v-if="files.length != 0">
              Statistics about your files. Remember to:
            </p>

            <ul class="file-upload-tips">
              <li>Export IFC spaces for each room to be simulated</li>
              <li>Classify walls, windows, doors and curtain walls</li>
              <li>Include context</li>
            </ul>

            <div class="fileContainer" :class="{ uploaded: files.length != 0 }">
              <div class="fileManager" @drop="drophandler">
                <div class="file-title" v-if="files.length != 0">
                  UPLOADED FILES
                </div>
                <div class="file" v-for="file in files" :key="file">
                  <div class="name">{{ file.name }}</div>
                  <div class="progressBar" v-if="file.progress < 100">
                    <div
                      class="progress"
                      :style="{ width: `${file.progress}%` }"
                    ></div>
                    <div class="lable">{{ file.progress }}%</div>
                  </div>
                  <div class="filesize">
                    {{ (file.size / (1024 * 1024)).toFixed(1) }} MB
                  </div>
                  <div class="download"><Icon>download</Icon></div>
                  <div class="delete">
                    <Icon @click="deletefile(file._id)">delete</Icon>
                  </div>
                </div>

                <div class="browse" v-if="jobStatus < 5 && jobStatus != -1">
                  <div
                    class="upload-field"
                    @click="fileinput.click()"
                    v-if="files.length == 0"
                  >
                    <div class="upload-field-placeholder">
                      <img src="../assets/images/IFC.png" />
                    </div>
                    <div class="upload-field_text">
                      <div class="upload-field_text-big">
                        Drop your files here, or
                        <span class="blue pointer">browse</span>
                      </div>
                      <div class="upload-field_text-small">Supports: .IFC</div>
                    </div>
                  </div>
                  <button
                    v-else
                    @click="fileinput.click()"
                    class="flex justify-center items-center"
                  >
                    <Icon>add</Icon> <span>Add more files&nbsp;&nbsp;</span>
                  </button>
                  <input
                    type="file"
                    ref="fileinput"
                    hidden
                    @change="uploadhandler"
                  />
                </div>
              </div>
            </div>

            <table class="ifc-table">
              <thead>
                <tr class="table-titles">
                  <td></td>
                  <td class="left">IFC Entities</td>
                  <td class="right">Count</td>
                  <td class="right">Points</td>
                </tr>
              </thead>
              <tr v-for="(value, key) in ifcObjs" :key="value">
                <td>
                  <Icon>{{ value.icon }}</Icon>
                </td>
                <td class="ifc-key">
                  {{ key.replace("IFC", "")
                  }}<span v-if="value.size == 0" class="warning">
                    {{ `* required` }}</span
                  >
                </td>
                <td class="ifc-value">{{ value.size }}</td>
                <td class="ifc-value">{{ value.points }} pt</td>
              </tr>
              <tr class="spacer">
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td class="ifc-point" colspan="2">IFC complexity:</td>
                <td class="ifc-point">{{ totalcomplex }} pt</td>
              </tr>
            </table>

            <!-- <p class="light-text small-text bottom-spacing">
              Our pricing is based on model complexity, which symbolises the
              amount of objects in the IFC files.
            </p> -->

            <!-- <div class="form-group">
              <span class="label"><Icon>fingerprint</Icon></span>
              <span class="approve"><Icon>check</Icon></span>
              <input
                class="form-field"
                type="text"
                placeholder="Project name"
              />
            </div>

            <div class="form-group">
              <span class="label"><Icon>place</Icon></span>
              <span class="approve"><Icon>check</Icon></span>
              <input class="form-field" type="text" placeholder="Location" />
            </div> -->

            <!-- <h4>Project will be billed to</h4>
            <div class="invoice-container">
              <div class="invoice-header">
                <span class="label">INVOICE</span>
                <div class="company-info">
                  <div class="titles">
                    <div class="name">dataTrees AS</div>
                    <div class="number">926 577 972</div>
                  </div>
                  <div class="adress">
                    <p>Faktura<br />Krepsens vei 1<br />1665 Rolvsøy</p>
                  </div>
                </div>
              </div>
              <div class="project-header">
                <div class="titles">
                  <div class="label">For project</div>
                  <div class="project-info">
                    <span class="title">Project Name</span>
                    <p class="desc">Location<br />Size<br />Report</p>
                  </div>
                </div>
                <div class="price">
                  <div class="label">Price</div>
                  <div class="amount">NOK 5.000</div>
                  <span class="status">EXPRESS</span>
                </div>
              </div>
            </div> -->
          </div>

          <!-- PROJECT SIZES -->
          <div
            v-if="products[productSize] && !included"
            class="project-size-info"
          >
            <div class="flex flex-align-center">
              <div class="project-size-info-title">Your project size:</div>
              <ul>
                <li v-for="(value, key) in products" :key="key">
                  <div
                    class="size-bubble"
                    :class="key == productSize ? 'active' : ''"
                  >
                    {{ key }}
                  </div>
                  <span
                    v-if="value.COMPLEX"
                    class="size-pt"
                    :class="key == productSize ? 'active' : ''"
                  >
                    {{ value.COMPLEX[0] + " pt" }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="content left-text">
          <div v-if="canProcessOrder && !included">
            <!-- <h4>Select reflection and transmission parameters</h4>
            <div class="user-details">
              <form class="pricing-container flex-row">
                <input type="radio" />
                <p class="light-text" v-if="files.length != 0">Conservative:</p>
                <ul class="file-upload-tips">
                  <li>floors: 0.2</li>
                  <li>interior walls: 0.5</li>
                  <li>ceilings: 0.7</li>
                  <li>windows: 0.6</li>
                </ul>
                <input type="radio" />
                <p class="light-text" v-if="files.length != 0">Standard:</p>
                <ul class="file-upload-tips">
                  <li>floors: 0.3</li>
                  <li>interior walls: 0.6</li>
                  <li>ceilings: 0.8</li>
                  <li>windows: 0.7</li>
                </ul>
                <input type="radio" />
                <p class="light-text" v-if="files.length != 0">Optimist:</p>
                <ul class="file-upload-tips">
                  <li>floors: 0.4</li>
                  <li>interior walls: 0.8</li>
                  <li>ceilings: 0.9</li>
                  <li>windows: 0.75</li>
                </ul>
              </form>
            </div>

            <h4>Add comments</h4> -->

            <h4>Choose your package</h4>
            <div v-if="products[productSize]">
              <form class="pricing-container flex-column">
                <div
                  v-for="(value, key, index) in products[productSize].PACKAGE"
                  :key="key"
                >
                  <div
                    class="PricingSelectionBox blue-box noselect"
                    :class="selectedPackage == key ? 'selected' : ''"
                  >
                    <input
                      class="hidden custom-radio"
                      :id="index"
                      type="radio"
                      :value="key"
                      v-model="selectedPackage"
                      :checked="key == 'STANDARD' ? true : false"
                    />
                    <label :for="index">
                      <img
                        src="https://assets.website-files.com/611fd8c5512ada66133ca362/612095cc5c8d97148ad733bf_bg.svg"
                        loading="lazy"
                        alt=""
                        class="background-asset"
                      />
                      <div class="top-half">
                        <span class="title">{{ key }}</span>
                        <span class="price">{{ value.price }} kr</span>
                      </div>
                      <p class="description">
                        {{ $t(`packageDes.${key}`) }}
                      </p>
                      <div>
                        <!-- class="bottom-half" -->
                        <ul class="includes">
                          <li>
                            <span
                              class="includes-item"
                              :class="{ 'opacity-2': !value.iterations }"
                            >
                              <Icon>loop</Icon>
                              <span v-if="value.iterations">{{
                                value.iterations
                              }}</span>
                              <span v-else>0</span>
                              <span class="includes-text"> simulation(s)</span>
                            </span>
                          </li>
                          <li>
                            <span
                              class="includes-item"
                              :class="{ 'opacity-2': !value.reports }"
                            >
                              <Icon>description</Icon>
                              <span v-if="value.reports">{{
                                value.reports
                              }}</span>
                              <span v-else>0</span>
                              <span class="includes-text"> report(s)</span>
                            </span>
                          </li>
                        </ul>
                        <span v-if="index === 1" class="discount"
                          >SAVE 15%</span
                        >
                        <span v-else-if="index === 2" class="discount"
                          >SAVE 20%</span
                        >
                      </div>
                      <!-- <div class="select-option">Select</div> -->
                    </label>
                  </div>
                </div>
              </form>
            </div>

            <!-- <div class="checkboxes">
            <label class="checkbox bounce">
              <input type="checkbox" />
              <svg viewBox="0 0 21 21">
                <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
              </svg>
              <span class="label">TEK report</span>
            </label>

            <label class="checkbox bounce">
              <input type="checkbox" />
              <svg viewBox="0 0 21 21">
                <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
              </svg>
              <span class="label">BREEAM report</span>
            </label>
          </div> -->

            <!-- <section class="dropzone">
            <div class="anonymizer-upload">
              <div class="dropzone">
                <input type="file" accept="image/*" id="anonymizer-input" />
                <div class="status">
                  <div class="icon">
                    <svg width="100%" height="100%">
                      <use xlink:href="#statusPending"></use>
                    </svg>
                  </div>
                </div>
                <div class="browse">
                  <span>Upload</span> or drop your file here
                </div>

                <div class="requirements">
                  <div>IFC · Max size 2Gb</div>
                </div>
              </div>
            </div>
          </section> -->

            <!-- <input type="file" id="upload-file" @change="loadfile" /> -->

            <!-- <div class="upload-details">
            <div class="drop" :class="{ 'is-uploaded': true }">
              <div class="intro">
                <h4>Drop your file here</h4>
                <p>Supports: .IFC</p>
              </div>
              <div class="center" style="display: none">
                <label for="upload-file" id="file-drag">
                  Upload
                  <input
                    type="file"
                    id="upload-file"
                    hidden
                    @change="loadfile"
                  />
                </label>
              </div>
            </div>
          </div> -->
            <div class="top-border">
              <span class="label">Comment:</span>
              <div class="ifc-table">
                <textarea
                  class="form-field"
                  style="margin: 20px 0px"
                  v-model="comment"
                  @blur="updatejob('comment', comment, id)"
                  rows="4"
                ></textarea>
              </div>
            </div>

            <h4 class="top-border">Payment method</h4>

            <form class="pricing-container">
              <div
                class="PricingSelectionBox blue-box"
                :class="paymentMethod == 'invoice' ? 'selected' : 'opacity-7'"
                v-if="company"
              >
                <input
                  class="hidden"
                  id="payment_1"
                  type="radio"
                  value="invoice"
                  v-model="paymentMethod"
                />
                <label for="payment_1" class="small flex items-center">
                  <!-- <Icon>description</Icon> -->
                  <img
                    src="../assets/images/invoice.svg"
                    intrinsicsize="512 x 512"
                    width="40"
                    height="30"
                    alt="Invoice"
                    title="Invoice"
                    class="icon"
                  />
                  <span class="title">Invoice</span>
                </label>
              </div>
              <div
                class="PricingSelectionBox blue-box small flex items-center"
                :class="'opacity-7'"
                v-else
              >
                <!-- <Icon>description</Icon> -->
                <img
                  src="../assets/images/invoice.svg"
                  intrinsicsize="512 x 512"
                  width="40"
                  height="30"
                  alt="Invoice"
                  title="Invoice"
                  class="icon"
                />
                <span class="title"
                  ><a
                    href="mailto:info@trees.no?subject=new invoice account&body=Dear André, Franz and Stian,%0D%0A%0D%0AI would like to apply for a company account to use the INVOICE PAYMENT option on wwww.otonomi.no.%0D%0A%0D%0AHere is the necessary information.%0D%0ACompany name:%0D%0ACompany Organisation Number:%0D%0AContact email Project Leader:%0D%0AContact email Project Architect:%0D%0AContact email Other Project Member: %0D%0A%0D%0AThank you!"
                    >Request invoice account
                  </a>
                </span>
              </div>

              <div
                class="PricingSelectionBox blue-box"
                :class="paymentMethod == 'stripe' ? 'selected' : 'opacity-7'"
              >
                <input
                  class="hidden"
                  id="payment_2"
                  type="radio"
                  value="stripe"
                  v-model="paymentMethod"
                  checked
                />
                <label for="payment_2" class="small flex items-center">
                  <!-- <Icon>credit_card</Icon> -->
                  <img
                    src="../assets/images/credit-card.svg"
                    intrinsicsize="512 x 512"
                    width="40"
                    height="40"
                    alt="Credit Card"
                    title="Credit Card"
                    class="icon"
                  />
                  <span class="title">Credit Card</span>
                </label>
              </div>
            </form>

            <div
              class="invoceshema top-border"
              v-if="paymentMethod == 'invoice'"
            >
              <h4>
                Viderefakturer
                <input
                  type="checkbox"
                  name=""
                  v-model="invoceform.defer"
                  id=""
                />
              </h4>

              <ul class="ifc-table" v-if="invoceform.defer">
                <li>
                  <span class="label">Name:</span>
                  <input
                    class="form-field"
                    type="text"
                    v-model="invoceform.name"
                  />
                </li>
                <li>
                  <span class="label">Email:</span>
                  <input
                    type="text"
                    class="form-field"
                    v-model="invoceform.email"
                  />
                </li>
                <li>
                  <span class="label">Address:</span>
                  <input
                    class="form-field"
                    type="text"
                    v-model="invoceform.address"
                  />
                </li>
                <li>
                  <span class="label">Zip:</span>
                  <input
                    class="form-field"
                    type="text"
                    v-model="invoceform.zip"
                  />
                </li>
                <li>
                  <span class="label">City:</span>
                  <input
                    class="form-field"
                    type="text"
                    v-model="invoceform.city"
                  />
                </li>
                <li>
                  <span class="label">Country:</span>
                  <input
                    class="form-field"
                    type="text"
                    v-model="invoceform.country"
                  />
                </li>
              </ul>
            </div>

            <h4 class="top-border">Order info - eks mva</h4>

            <table class="ifc-table" v-if="selectedPackage">
              <thead>
                <tr class="table-titles">
                  <td class="left">Product</td>
                  <td class="right">Quantity</td>
                  <td class="right">Price</td>
                </tr>
              </thead>
              <tr>
                <td class="ifc-key">{{ selectedPackage }}</td>
                <td class="ifc-value">1</td>
                <td class="ifc-value">
                  {{ products[productSize].PACKAGE[selectedPackage].price }} kr
                </td>
              </tr>
              <tr class="spacer">
                <td></td>
              </tr>
              <tr>
                <td>Subtotal</td>
                <td></td>
                <td class="ifc-value">
                  {{ products[productSize].PACKAGE[selectedPackage].price }} kr
                </td>
              </tr>
              <!-- <tr>
                <td>VAT (25%)</td>
                <td></td>
                <td class="ifc-value">
                  {{
                    products[productSize].PACKAGE[selectedPackage].price * 0.25
                  }}
                  kr
                </td>
              </tr> -->
              <tr class="spacer">
                <td></td>
              </tr>
              <tr>
                <td class="total">Order Total</td>
                <td></td>
                <td class="ifc-point">
                  {{ products[productSize].PACKAGE[selectedPackage].price }}
                  kr
                </td>
              </tr>
            </table>
          </div>

          <div class="user-details">
            <!-- <p class="light-text">
              We need some information before we can start your simulation
            </p>

            <div class="form-group">
              <span class="label">IFC</span>
              <span class="approve"><Icon>check</Icon></span>
              <input
                class="form-field"
                type="text"
                placeholder="Select IFC file to upload"
              />
            </div> -->

            <Button
              v-if="canProcessOrder"
              bgColor="var(--color-dark-green)"
              txtColor="#ffffff"
              shadow="var(--shadow-green)"
              style="border-radius: 12px; width: 50%; margin: 0 auto"
              @click="processOrder"
              ><Icon>outbox</Icon
              >{{ included ? "Simulate" : "Order Now" }}</Button
            >
            <Button
              v-else
              bgColor="var(--color-light-grey)"
              txtColor="var(--color-text-lighter)"
              shadow="var(--shadow-medium)"
              style="border-radius: 12px; width: 50%; margin: 0 auto"
              ><Icon>outbox</Icon>Complete form before ordering</Button
            >

            <div class="payment-notice text-center">
              <Icon>lock</Icon> Payments are secure and encrypted
            </div>
          </div>

          <!-- <form @submit.prevent="">
            <label for="file">{{ $t("newJob.lableFile") }}:</label
            ><input type="file" name="file" id="" accept=".ifc,.ifczip" /> -->
          <!-- can also be a drag and drop box with and add button to select a file. mabye more modern. we can have progress for each element a bit like dropbox. google drive etc. -->
          <!-- <label for="projectSize">{{ $t("newJob.lableSize") }}:</label
            ><select name="projectSize" id="">
              <option value="S">S</option>
              <option value="M">M</option>
              <option value="L">L</option>
              <option value="XL">XL</option>
            </select>
          </form> -->
        </div>
      </section>
    </section>
  </main>
</template>

<style lang="scss" scoped>
@import "../css/home.scss";

.page {
  padding: 20px 30px;
  box-sizing: border-box;
}
.top-border {
  border-top: 1px solid var(--color-light-blue-grey);
  padding-top: 20px;
}
.payment-notice {
  color: var(--color-text-lighter);
  font-size: 0.8rem;
  margin-top: 15px;

  .material-icons {
    font-size: 0.9rem;
  }
}

.label {
  font-size: 1rem;
  font-weight: 600;
}

.fileContainer {
  background: #fff;
  padding: 30px;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 12px;
  .fileManager {
    border: 2px dashed var(--color-text-lighter);
    margin: 0px auto;
    position: relative;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;

    &:hover {
      border: 2px dashed var(--color-text);
      img {
        transform: rotate(10deg);
      }
    }

    .file-title {
      font-weight: 400;
      text-transform: uppercase;
      font-size: 0.8rem;
      padding-left: 36px;
      height: 27px;
      color: var(--color-text-light);
    }

    .file {
      margin: 8px;
      border-bottom: 1px solid var(--color-text-lighter);
      display: grid;
      align-items: center;
      color: var(--color-text-lighter);
      grid-template-rows: 1fr;
      grid-template-columns: 1fr auto auto 25px 25px;
      grid-template-areas: "name progressBar filesize download delete";

      &:hover {
        color: var(--color-text);
        .name {
          color: var(--color-text);
        }
      }

      &:last-child {
        border-bottom: 10px solid var(--color-text-lighter);
      }

      .name {
        color: var(--color-blue);
        grid-area: name;
        font-size: 1rem;
        text-align: left;
        padding-left: 36px;
        margin-right: 20px;
        font-weight: 600;
        text-overflow: clip;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .filesize {
        grid-area: filesize;
        padding-right: 10px;
        color: var(--color-text-lighter);
        font-size: 0.9rem;
        margin-top: auto;
        margin-bottom: 5px;
      }
      .progressBar {
        grid-area: progressBar;
        width: 150px;
        background-color: lightgray;
        border-radius: 15px;
        height: 90%;
        position: relative;
        .progress {
          height: 100%;
          background-color: green;
          border-radius: 15px;
        }
        .lable {
          position: absolute;
          transform: translatex(-50%);
          left: 50%;
          top: 0px;
        }
      }
      .download {
        grid-area: download;
        cursor: pointer;

        .material-icons {
          display: flex;
        }
      }
      .delete {
        grid-area: delete;
        cursor: pointer;
      }
    }
    .browse {
      bottom: 0px;
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        padding: 7px 11px;
        border-radius: 22px;
        color: white;
        font-weight: 600;
        background: var(--color-blue);
      }
    }
  }

  &.uploaded {
    background: transparent;

    .fileManager {
      border: 0px;
      .file {
        border: 0px;
        margin: 0px;
      }
    }
  }
}

.ifc-table {
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  font-size: 0.8rem;
  border-top: 1px solid white;

  .material-icons {
    font-size: 1.1rem;
    color: var(--color-dark-blue);
  }

  .total {
    font-weight: 700;
    font-size: 1.2rem;
  }
  .spacer {
    height: 13px;
  }
}
.table-titles {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 0.8rem;
  height: 40px;
  color: var(--color-text-light);
  .left {
    text-align: left;
  }
  .right {
    text-align: right;
  }
}
.ifc-key {
  text-align: left;
  font-weight: 600;

  .warning {
    color: var(--color-red);
    font-size: 0.75rem;
    font-weight: 500;
    margin-left: 6px;
  }
}
.ifc-value {
  text-align: right;
  padding-left: 20px;
  color: var(--color-text-light);
}
.ifc-point {
  text-align: right;
  font-weight: 600;
  font-size: 1rem;
}

.project-size-info {
  background: var(--color-bg-dark);
  width: calc(100% + 60px);
  margin: 20px -30px 0;
  color: white;
  padding: 30px;
  box-sizing: border-box;

  .project-size-info-title {
    font-size: 1.5rem;
    font-weight: 800;
  }

  ul > li {
    display: inline-block;
    margin: 0 20px;
    text-align: center;

    .size-pt {
      font-size: 0.65rem;
      opacity: 0.7;

      &.active {
        opacity: 1;
        font-weight: 700;
        color: white;
      }
    }

    .size-bubble {
      position: relative;
      background: var(--color-bg-medium);
      color: var(--color-text-light);
      width: 35px;
      height: 35px;
      line-height: 35px;
      font-size: 0.8rem;
      font-weight: 500;
      border-radius: 100%;
      margin: 0 auto;

      &.active {
        background: var(--color-medium-green);
        font-size: 0.9rem;
        font-weight: 800;
        color: white;

        // &::after {
        //   content: "▲";
        //   color: white;
        //   position: absolute;
        //   bottom: -10px;
        //   right: 50%;
        // }
      }
    }
  }
}

.order-header {
  background: linear-gradient(180deg, #f1f5fb 20%, #ffffff 100%);
  width: calc(100% + 58px);
  margin: 20px -29px 0;
  color: var(--color-text);
  padding: 30px;
  box-sizing: border-box;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 700;
  border-top: 1px solid var(--color-light-blue-grey);
}

.upload-field {
  margin: 22px auto;
  text-align: center;

  .upload-field-placeholder {
    img {
      width: 110px;
      transition: transform 0.3s ease-in-out;
    }
  }
  .upload-field_text-big {
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 2rem;
  }
  .upload-field_text-small {
    font-size: 0.8rem;
    color: var(--color-text-lighter);
  }
}

.file-upload-tips {
  list-style-type: disc;
  font-size: 00.8rem;
  color: var(--color-blue);
  margin-left: 13px;
}
</style>
